@media (max-width: 600px) {
    .column_height {
        height: 60px;
    }
}

@media (min-width: 600px) {
    .column_height {
        height: 45px;
    }
}

.ant-radio-checked .ant-radio-inner::after {
    transform: scale(1);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
        padding: 2px 0 0px !important;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
    }
}
