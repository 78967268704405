@import '~antd/lib/style/themes/default.less';
@import '../../assets/less/default.less';

.layout-portal-logo {
    margin-top: 26px;
    margin-left: 2px;
    margin-bottom: 25px;
    width: 55%;
}

.layout-portal-content {
    /* margin: 24px 16px; */
    padding: 24;
    height: 100%;
    min-height: 280;
}

.layout-portal-main-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.site-layout {
    background: @white;
}

.site-layout .site-layout-background {
    background: @white;
}

.layout-portal-sider {
    overflow: hidden;
    height: 100vh;
    position: fixed;
    left: 0;
}

/* scroller */

.layout-portal-scrollbar {
    float: left;
    height: 100%;
    overflow-y: auto;
}

#scroller::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

#scroller::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

#scroller::-webkit-scrollbar-thumb {
    background-color: @black;
}

.primary-color {
    color: @layout-header-background;
}

button#metting_info{
    background-color: #ffffff;
    color:@layout-header-background;
    border-color:@layout-header-background;
}

button#metting_info:hover{
    background-color: @layout-header-background;
    color:#ffffff;
    border-color:#ffffff;
}

.form_item_search{
    margin: 0px !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}


/* For Mobile Portrait View */
@media screen and (max-device-width: 430px)
and (orientation: portrait) {
	.ant-layout.site-layout{
        margin-left: 40px !important;
	}
    .ant-layout-sider-collapsed {
        width: 40px !important;
        min-width: 40px !important;
    }
    .ant-layout-sider-trigger {
        display: none;
    }
}


/* For Mobile Portrait View */
@media screen and (max-device-width: 335px)
and (orientation: portrait) {
    .ant-layout.site-layout{
        margin-left: 40px !important;
	}
    .ant-layout-sider-collapsed {
        width: 40px !important;
        min-width: 40px !important;
    }
    .ant-layout-sider-trigger {
        display: none;
    }
}
