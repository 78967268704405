// .responsive-row td { white-space: nowrap; }

.table_children_responsive {
    width: 100%;
}

.filter_btn{
    float: right;
    margin-right: 10px;
}

@media (max-width: 576px) {

    .table_children_responsive {
        width: 30%;
        position: relative;
        left: -50px;
    }
}

@media (min-width: 1200px) {

    .button-filter{
        max-width: 75px !important;
        text-align: center;
    }

    .filter_btn {
        float: none !important;
        margin-right: 0px !important;
    }
}

@media (min-width: 1050px)  {
    .button-filter{
        max-width: 75px !important;
        text-align: center;
    }

    .filter_btn {
        float: none;
        margin-right: 0px;
    }
}

@media (min-width: 617px) and (max-width:  800px) {
    .table_children_responsive {
        width: 45%;
        position: relative;
        left: -50px;
    }
}

@media (min-width: 800px) and (max-width:  1050px) {
    .table_children_responsive {
        width: 55%;
        position: relative;
        left: -50px;
    }
}

@media (min-width: 1050px)  {
    .table_children_responsive {
        width: 60%;
    }
}

.column_without_padding{
    padding:0px 3px !important;
    width: 10%;
}

.column_without_padding.table_action{
    width: 5%;
}

th.column_without_padding > div.ant-table-column-sorters-with-tooltip > div.ant-table-column-sorters {
    padding: 0px 0px;
}

tbody.ant-table-tbody > tr > td.ant-table-cell{
    padding: 3px 6px;
}

@media (min-width: 1200px){
.rich_editor {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 13%;
}}
.rich_editor div {
    margin-right: 0px !important;
}

.user_state div {
    margin-right: 0px !important;
}

@media (min-width: 1200px){
.user_state {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 15.666667%;;
}}

@media (min-width: 1200px){
.course_bookmark {
    display: block;
    flex: 0 0 42.16666667%;
    max-width: 50.5% !important;
}}

thead.ant-table-thead tr th.ant-table-cell.title_banner{
    text-align: center;
}

div.banner_edit div div div:nth-child(2) div.ql-editor {
    min-height: 5em;
}

.width100{
    width: 100%!important;
}



.force_selected{
    color: #fff!important;
    background: #1c69ad!important;
    border-color: #1c69ad!important;
}

.no_force_selected{
    color: #1c69ad!important;
    background: #fff!important;
    border-color: #1c69ad!important;
}
.hide_rule_message .ant-form-item-explain{
    display: none !important;
}
div.job_function span.ant-select-arrow{
    display: none;
}

label[for="user_default_course"]{
    height: unset;
    vertical-align: sub;
}

label[for="user_district_as_entered"]{
    height: unset;
    vertical-align: sub;
}
label[for="user_school_as_entered"]{
    height: unset;
    vertical-align: sub;
}

td.ant-table-cell span.anticon.anticon-close-circle svg > path:nth-child(1) {
    fill: red;
    border-color: red;
}

td.ant-table-cell span.anticon.anticon-close-circle svg > path:nth-child(2) {
    fill: red;
    border-color: red;
}
