@import './../../assets/less/default.less';

.login-layout {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: @login-background-color;
}

.login-layout-outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: @login-background-color;
}

.login-layout-logo {
  width: 64%;
  margin: auto 18%;
}

.textLogin {
  color: @login-text-color;
  text-align: center;
  font-size: 15px;
}

.imageLogin {
  float: right;
  width: 106px;
  margin-right: 35px;
}

@media (max-width: 576px) {
  .textLogin {
    float: none;
    text-align: center;
    margin: 0 auto;
  }

  .imageLogin {
    float: none;
    width: 106px;
    margin-right: 0px;
  }

  .login-layout {
    height: 110%;
  }
}

@media (min-width: 577px) and (max-width: 616px) {
  .textLogin {
    float: right;
    text-align: center;
  }

  .imageLogin {
    float: left;
    width: 106px;
    margin-right: 0px;
  }
}

.forgotPassword {
    color: #fdfdfd !important;
    font-size: 16px !important
}

.forgotPassword:hover {
    color: #1c69ad !important;
 }
