.ant-popover-title {
    text-align: center;
}

.ant-popover-inner-content {
    padding: 10px;
}

.ant-popover-content {
    box-shadow: 0px 0px 10px 4px #dbdbdb;
}

.alp-projects-table .ant-table .ant-table-thead > tr > th {
    background-color: white;
}

