div.ant-tabs-tab-btn {
    line-height: initial;
    white-space: normal;
    font-size: 13px;
    text-align: center;
}

div.ant-tabs-tab{
    white-space: normal;
    max-width: 75px;
    padding: 5px !important;
}

div.ant-tabs-tab:nth-child(13) {
    max-width: 5%;
}