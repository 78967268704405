.fc-year-button {
  padding: 0px !important;

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-selection-item {
    color: #0051a0;
  }

  .ant-select-arrow {
    color: #0051a0;
  }
}

.fc-today-button {
  text-transform: capitalize !important;

  &:disabled {
    background-color: #0051a0 !important;
    opacity: 1 !important;
    color: white !important;
  }
}
