
.table-search thead {
    height: 2px;
    line-height: 0px !important;
}


.column-avatar{
    flex: unset !important;
}


.table-search thead > tr > th {
    border-bottom: unset !important;
    color:#313777 !important;
}

.modal-search .ant-modal-content .ant-modal-body{
    padding-top: 0px !important;
}

.modal-search .ant-modal-content .ant-modal-header{
    border-bottom: 1px solid #d2cdcd !important;

}

.table-search .ant-table-cell {
    border-color: #d2cdcd !important;
    padding-left: 0px !important;
}

.span-cohorts{
    margin-bottom: 0.5em !important;
}

.column_text_attendance{
    line-height: 17px;
    width: 287px;
}
