.background-select {
  position: relative;
  background-color: rgb(255, 242, 204);
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}

.background-input {
  background-color: rgb(255, 242, 204) !important;

}

.column_training {
  padding: 0px !important;
}

tr>th.ant-table-cell.column_roster {
  padding: 0px !important;
  text-align: end !important;
}


.ant-table-cell.column_roster {
  padding: 0px !important;
  text-align: end !important;
}

.ant-table-cell.column_session {
  padding: 3px 0px !important;

}

tr td[colspan].ant-table-cell.column_session {
  background-color: #dddddd;
  color: #0050a0;
  position: sticky;
  top: 0;
  z-index: 1;
}

tr td[colspan].ant-table-cell.column_session:hover {
  background-color: #dddddd;
  color: #0050a0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.next-cohort {
  display: inline-block;
  height: 100%;
  padding: 0px 5px;
  background-color: #548135;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
}

.ant-table-cell.column_participants {
  padding-right: 10px !important;
}

.ant-modal-confirm-error .ant-modal-confirm-content div {
  padding-left: 38px;
}

.table_materials td.ant-table-cell{
    padding: 0px 6px !important;
}
.table_materials .ant-table-thead > tr > th{
    padding: 0px 6px !important;
}


div.title_course_materials{
    border: none !important;
    text-align: center;
    color: #2731bd;
    font-weight: bold;
    padding: 15px;
}

button.ant-btn.ant-btn-primary.drive_state_coordinator {
    height: 100%;
    font-size: 11px;
    line-height: 1em;
}

button.ant-btn.ant-btn-primary.drive_state_coordinator:focus {
    color: #1c69ad;
    background: #fff;
    border-color: #1c69ad;
}

.tableAttendanceAuditCohort .ant-table-header tr .ant-table-cell {
    padding : 0px 16px 0px 8px;
}

.modalAttendanceAuditCohort .ant-modal-body {
    padding-top : 0px;
}

.modalAttendanceAuditCohort .ant-modal-header {
    border-bottom: unset;
    margin-bottom: -5px;
}

